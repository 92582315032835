import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "@/store";

export const selectUserUser = (state: RootState) => state.user.user;
export const selectSignedIn = (state: RootState) => state.user.signedIn;

export const selectIsUserLoaded = createSelector(
  [selectUserUser],
  (userUser) => userUser !== null,
);

export const selectIsUserSuperAdmin = createSelector(
  [selectUserUser],
  (userUser) => userUser?.role === "superadmin",
);

export const selectIsUserOwnerOrAdmin = createSelector(
  [selectUserUser],
  (userUser) => {
    return (
      userUser?.role === "owner" ||
      userUser?.role === "superadmin" ||
      userUser?.role === "admin"
    );
  },
);

export const selectIsUserOwner = createSelector(
  [selectUserUser],
  (userUser) => {
    return userUser?.role === "owner" || userUser?.role === "superadmin";
  },
);

export const selectIsUserGuest = createSelector(
  [selectUserUser],
  (userUser) => {
    return userUser?.role === "guest";
  },
);

export const selectEmailVerificationRequired = createSelector(
  [selectUserUser],
  (userUser) => {
    const emailVerificationRequired = userUser?.confirmed === false;
    return emailVerificationRequired;
  },
);
