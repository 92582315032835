import { Link } from "react-router-dom";

import { Heading1, SubNavigationItem, SubNavigationNav } from "@/design-system";
import {
  selectIsUserOwnerOrAdmin,
  selectIsUserSuperAdmin,
} from "@/selectors/user";
import { SHOW_DEV_TOOLS } from "../../helpers";
import { useAppSelector } from "../../store";

import styles from "./styles.module.css";

type SubNavigationAccountProps = { currentNavItem: string };

export function SubNavigationAccount({
  currentNavItem,
}: SubNavigationAccountProps) {
  const isSuperAdmin = useAppSelector(selectIsUserSuperAdmin);
  const isOwnerOrAdmin = useAppSelector(selectIsUserOwnerOrAdmin);

  return (
    <SubNavigationNav>
      <Heading1 className={styles.mainHeading}>Account</Heading1>
      <SubNavigationItem active={currentNavItem === "account"}>
        <Link to={"/account"}>Account Details</Link>
      </SubNavigationItem>
      {isOwnerOrAdmin && (
        <SubNavigationItem active={currentNavItem === "buy-credits"}>
          <Link to={"/account/buy-credits"}>Buy&nbsp;Credits</Link>
        </SubNavigationItem>
      )}
      {isOwnerOrAdmin && (
        <SubNavigationItem active={currentNavItem === "billing"}>
          <Link to={"/account/billing"}>Usage &amp; Billing</Link>
        </SubNavigationItem>
      )}
      <SubNavigationItem active={currentNavItem === "notifications"}>
        <Link to={"/account/notifications"}>Notifications</Link>
      </SubNavigationItem>
      {isOwnerOrAdmin && (
        <SubNavigationItem active={currentNavItem === "team"}>
          <Link to={"/account/team"}>Team</Link>
        </SubNavigationItem>
      )}
      {isSuperAdmin && (
        <SubNavigationItem active={currentNavItem === "super-admin"}>
          <Link to={"/super-admin"}>Superadmin</Link>
        </SubNavigationItem>
      )}
      {SHOW_DEV_TOOLS && (
        <SubNavigationItem active={currentNavItem === "account-dev"}>
          <Link to={"/account/dev"}>Account Dev</Link>
        </SubNavigationItem>
      )}
    </SubNavigationNav>
  );
}

export default SubNavigationAccount;
