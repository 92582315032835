import { ReactNode, useRef, useState } from "react";
import { cn } from "@sys42/utils";
import isUndefined from "lodash/isUndefined";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";

import { HeaderWithLogo } from "@/components/VideoPlayerWithSidebar/HeaderWithLogo";
import { ButtonPrimary } from "@/design-system";
import { useAppSelector } from "@/store";
import Loading from "../Loading"; // TODO - no import from containers

import { UserbrainVideoPlayerApi } from "./components/VideoPlayer/types";
import { SharedPlayer } from "./SharedPlayer";
import { SidebarShared } from "./SidebarShared";
import { useTaskStepsWithResponse } from "./utils/useTaskStepsWithResponse";

import styles from "./shared.module.css";
import videoStyles from "./styles.module.css";

export type OnClickTime = (timestamp: number) => void;

/**


  Shared Video

*/
export default function SharedVideo() {
  const [currentVideoSecond, setCurrentVideoSecond] = useState(0);

  const refPlayer = useRef<UserbrainVideoPlayerApi | null>(null);

  const video = useAppSelector((state) => state.sharedVideo.data);
  const videoError = useAppSelector((state) => state.sharedVideo.error);

  // we know better *fingers crossed* than tsc and assert the type
  const { hash: sharedHash } = useParams() as { hash: string };

  const { taskStepsWithResponse, isLegacyTask } =
    useTaskStepsWithResponse(video);

  function handleClickTime(timestamp: number) {
    refPlayer.current?.currentTime(timestamp);
    refPlayer.current?.play();
  }

  if (video === null || videoError) {
    return <Loading error={videoError}>Loading video</Loading>;
  }

  const hasFullAccess = !isUndefined(video.id);

  return (
    <Container title={video?.title}>
      <Header
        videoId={video.id}
        hasFullAccess={hasFullAccess}
        sharedHash={sharedHash}
      />
      <SharedPlayer
        sharedHash={sharedHash}
        refPlayer={refPlayer}
        video={video}
        onChangeCurrentSecond={setCurrentVideoSecond}
        taskSteps={taskStepsWithResponse}
        currentVideoSecond={currentVideoSecond}
      />
      <SidebarShared
        videoDuration={video.duration}
        onClickTime={handleClickTime}
        videoTitle={video.title}
        videoTestUrl={video.testUrl}
        taskStepsWithResponse={taskStepsWithResponse}
        currentVideoTime={currentVideoSecond}
        isLegacyTask={isLegacyTask}
        videoId={video.id}
        videoTranscript={video.transcript}
        currentVideoSecond={currentVideoSecond}
      />
    </Container>
  );
}

/**


  Container

*/
function Container({
  children,
  title,
}: {
  children: ReactNode;
  title: string;
}) {
  return (
    <div className={videoStyles.video}>
      <Helmet>
        <title>{title ?? "Video"} | Userbrain</title>
      </Helmet>
      {children}
    </div>
  );
}

/**


  Header

*/
function Header({
  videoId,
  sharedHash,
  hasFullAccess,
}: {
  videoId: number | undefined;
  sharedHash: string;
  hasFullAccess: boolean;
}) {
  const { user } = useAppSelector((state) => state.user);
  const isLoggedIn = Boolean(user?.email);

  return (
    <div className={cn(styles.header)}>
      <HeaderWithLogo isLoggedIn={isLoggedIn}>
        {hasFullAccess && (
          <ButtonPrimary
            className={styles.headerLoginButton}
            customTag={"a"}
            href={`/video/${videoId}`}
          >
            Open full player
          </ButtonPrimary>
        )}
        {!isLoggedIn && (
          <ButtonPrimary
            className={styles.headerLoginButton}
            customTag={Link}
            to={`/login?redirectTo=/shared/${sharedHash}`}
          >
            Log in
          </ButtonPrimary>
        )}
      </HeaderWithLogo>
    </div>
  );
}
