import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import semverGt from "semver/functions/gt";

import ModalFeaturePopup from "../../components/ModalFeaturePopup";

export function NewFeatureModal() {
  const dispatch = useDispatch();
  const featurePopupVersion = useSelector(
    (state) => state.user.user?.settings?.feature_popup_version,
  );
  const releases = useSelector((state) => state.releaseNotes.data?.releases);

  const featurePopups = useMemo(() => {
    if (!releases || typeof featurePopupVersion === "undefined") return [];
    const releasesToShow = releases.filter((release) =>
      semverGt(release.version, featurePopupVersion ?? "0.0.0"),
    );
    return releasesToShow
      .filter((release) => release.feature_popup)
      .map((release) => ({
        ...release.feature_popup,
        version: release.version,
      }));
  }, [featurePopupVersion, releases]);

  const featurePopup = featurePopups[0] ?? null;

  useEffect(() => {
    dispatch({ type: "RELEASE_NOTES_REQUEST" });
  }, [dispatch]);

  function handleClickDismiss() {
    const { version } = featurePopup;
    if (!version) return;
    dispatch({
      type: "FEATURE_POPUP_DISMISS_REQUEST",
      version,
    });
  }

  return (
    <ModalFeaturePopup
      isActive={!!featurePopup}
      onDismiss={handleClickDismiss}
      featurePopup={featurePopup}
    />
  );
}
