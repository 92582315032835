import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  ButtonPrimary,
  ErrorMessage,
  FormField,
  InputText,
  RadioGroup,
  RadioGroupItem,
  Stack,
  TextArea,
} from "@/design-system";
import {
  roleDescriptionAdmin,
  roleDescriptionCollaborator,
  roleDescriptionGuest,
} from "@/helpers/teamMembers";
import { isDevelopment, usePrevious } from "../../helpers";
import ButtonGroup from "../ButtonGroup";
import { Modal2 } from "../Modal";
import ModalHeader from "../ModalHeader";

import styles from "./styles.module.css";

export default function InviteTeamMemberModal(props) {
  const { isActive, onClose, ...restProps } = props;

  return (
    <Modal2
      isActive={isActive}
      onClose={onClose}
      maxWidth={"34rem"}
      content={
        <InviteTeamMemberModalContent onClose={onClose} {...restProps} />
      }
    />
  );
}

function InviteTeamMemberModalContent({
  onClose,
  fetching,
  error,
  onSendInvitation,
  onSuccess,
  onChangeEmail,
  onChangeRole,
  onChangeMessage,
  email,
  token,
  role,
  message,
  // usedGuests,
  // maxGuests,
  usedCollaborators,
  maxCollaborators,
  usedAdmins,
  maxAdmins,
}) {
  const [invitationSentSuccessfully, setInvitationSentSuccessfully] =
    useState(false);
  const [invitationSentEmail, setInvitationSentEmail] = useState(null);

  const prevFetching = usePrevious(fetching);
  useEffect(() => {
    if (prevFetching === true && fetching === false && !error) {
      setInvitationSentSuccessfully(true);
      setInvitationSentEmail(email);
      onSuccess();
    }
  }, [prevFetching, fetching, email, onSuccess, error]);

  function handleClickSend() {
    onSendInvitation(email, role, message);
  }

  let displayedErrorMessage = error?.message;
  if (error?.type === "limit_reached_admin") {
    displayedErrorMessage = (
      <>
        You have reached the maximum number of admins included in your plan.{" "}
        <Link to={"/account/billing"}>Upgrade&nbsp;your&nbsp;plan</Link>
      </>
    );
  } else if (error?.type === "limit_reached_collaborator") {
    displayedErrorMessage = (
      <>
        You have reached the maximum number of collaborators included in your
        plan. <Link to={"/account/billing"}>Upgrade&nbsp;your&nbsp;plan</Link>
      </>
    );
  }

  return invitationSentSuccessfully ? (
    <>
      <ModalHeader heading={"You’re all set!"} onClickCloseIcon={onClose} />
      <div className={styles.modalContent}>
        <p>{invitationSentEmail} was invited to join your team.</p>
        <p className={styles.subline}>
          Changed your mind? You can always revoke your invitation or remove
          access later.
        </p>
        {isDevelopment && (
          <p>
            <strong>DEV: </strong>
            <a
              href={`/invitation/${token}`}
              target={"_blank"}
              rel={"noreferrer"}
            >
              {`/invitation/${token}`}
            </a>
          </p>
        )}
      </div>
      <div className={styles.footer}>
        <ButtonGroup align={"right"}>
          <ButtonPrimary onClick={onClose}>Ok, thanks</ButtonPrimary>
        </ButtonGroup>
      </div>
    </>
  ) : (
    <>
      <ModalHeader
        heading={"Add a New Team Member"}
        onClickCloseIcon={onClose}
      />
      <div className={styles.modalContent}>
        <Stack>
          {displayedErrorMessage && (
            <ErrorMessage message={displayedErrorMessage} />
          )}
          <FormField
            label={"Who do you want to add?"}
            errorMessage={error?.fieldFeedback.email}
          >
            <InputText
              value={email}
              type={"email"}
              placeholder={"joe@example.com"}
              onChange={onChangeEmail}
              fullWidth
            />
          </FormField>

          <FormField label={"Select role"}>
            <RadioGroup value={role} onChange={onChangeRole}>
              <RadioGroupItem value={"guest"}>
                Guest <span className={styles.usage}>(unlimited)</span>
                <div className={styles.roleDescription}>
                  {roleDescriptionGuest}
                </div>
              </RadioGroupItem>
              <RadioGroupItem value={"collaborator"}>
                Collaborator{" "}
                <span className={styles.usage}>
                  ({usedCollaborators} of {maxCollaborators} used)
                </span>
                <div className={styles.roleDescription}>
                  {roleDescriptionCollaborator}
                </div>
              </RadioGroupItem>
              <RadioGroupItem value={"admin"}>
                Admin{" "}
                <span className={styles.usage}>
                  ({usedAdmins} of {maxAdmins} used)
                </span>
                <div className={styles.roleDescription}>
                  {roleDescriptionAdmin}
                </div>
              </RadioGroupItem>
            </RadioGroup>
          </FormField>

          <FormField label={"Message (optional)"}>
            <TextArea
              placeholder={"Type your message here…"}
              value={message}
              onChange={onChangeMessage}
              fullWidth
            />
          </FormField>
        </Stack>
      </div>
      <div className={styles.footer}>
        <ButtonGroup align={"right"}>
          <ButtonPrimary onClick={handleClickSend} disabled={fetching}>
            {fetching ? "Sending…" : "Send invite"}
          </ButtonPrimary>
        </ButtonGroup>
      </div>
    </>
  );
}
