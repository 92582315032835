import { AxiosError } from "axios";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import axios from "@/axios";
import { createReduxApiError } from "@/helpers-ts";
import {
  FEATURE_POPUP_DISMISS_FAILURE,
  FEATURE_POPUP_DISMISS_REQUEST,
  FEATURE_POPUP_DISMISS_SUCCESS,
  USER_FAILURE,
  USER_REQUEST,
  USER_SUCCESS,
  USER_UPDATE_FAILURE,
  USER_UPDATE_PASSWORD_FAILURE,
  USER_UPDATE_PASSWORD_REQUEST,
  USER_UPDATE_PASSWORD_SUCCESS,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SETTINGS_FAILURE,
  USER_UPDATE_SETTINGS_REQUEST,
  USER_UPDATE_SETTINGS_SUCCESS,
  USER_UPDATE_SUCCESS,
} from "@/reducers/user";
import { API_URL } from ".";

export function* watchUser() {
  yield takeLatest(USER_REQUEST, callUserApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callUserApi(): SagaIterator {
  try {
    const currentRefreshHash = yield select(
      (state) => state.user.userRefreshHash,
    );
    const response = yield call(fetchUser, currentRefreshHash);
    const user = response.data.data;
    const refreshHash = response.data.meta?.refresh_hash;
    yield put({ type: USER_SUCCESS, user, refreshHash });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: USER_FAILURE,
      error: createReduxApiError(error as AxiosError),
    });
  }
}

// FEATURE POPUP

export function watchDismissFeaturePopup() {
  return takeLatest(FEATURE_POPUP_DISMISS_REQUEST, callUserFeaturePopupApi);
}

function* callUserFeaturePopupApi(action: any): SagaIterator {
  try {
    const { version } = action;
    const response = yield call(patchUserSettings, {
      feature_popup_version: version,
    });
    if (response.data.status === "success") {
      yield put({ type: FEATURE_POPUP_DISMISS_SUCCESS, version });
    } else {
      throw new Error("Failed to dismiss feature popup");
    }
  } catch (error) {
    yield put({
      type: FEATURE_POPUP_DISMISS_FAILURE,
      error: createReduxApiError(error as AxiosError),
    });
  }
}

// UPDATE USER

export function* watchUserUpdate() {
  yield takeLatest(USER_UPDATE_REQUEST, callUserUpdateApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callUserUpdateApi(action: any): SagaIterator {
  try {
    const response = yield call(patchUser, {
      email: action.email,
      name: action.name,
    });
    const user = response.data.data;

    if (user) {
      yield put({ type: USER_UPDATE_SUCCESS, user });
    } else {
      yield put({ type: USER_UPDATE_SUCCESS });
    }

    yield put({
      type: "SNACKBAR_ADD",
      notificationType: "success",
      content: `Saved successfully`,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: USER_UPDATE_FAILURE,
      error: createReduxApiError(error as AxiosError),
    });
  }
}

export function* watchUserUpdatePassword() {
  yield takeLatest(USER_UPDATE_PASSWORD_REQUEST, callUserUpdatePasswordApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callUserUpdatePasswordApi(action: any): SagaIterator {
  try {
    const response = yield call(patchUser, {
      new_password: action.newPassword,
      new_password_confirmation: action.newPasswordConfirmation,
      current_password: action.currentPassword,
    });
    const user = response.data.data;

    if (user) {
      yield put({ type: USER_UPDATE_PASSWORD_SUCCESS, user });
    } else {
      yield put({ type: USER_UPDATE_PASSWORD_SUCCESS });
      yield put({
        type: "SNACKBAR_ADD",
        notificationType: "success",
        content: `Password updated`,
      });
    }
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: USER_UPDATE_PASSWORD_FAILURE,
      error: createReduxApiError(error as AxiosError),
    });
  }
}

// function that makes the api request and returns a Promise for response
function patchUser(data: { [key: string]: any }) {
  return axios({
    method: "patch",
    url: API_URL + "/user",
    data,
  });
}

// NEW WATCHER FOR USER UPDATE SETTINGS
export function* watchUserUpdateSettings() {
  yield takeLatest(USER_UPDATE_SETTINGS_REQUEST, callUserUpdateSettingsApi);
}

function* callUserUpdateSettingsApi(action: any): SagaIterator {
  try {
    const response = yield call(patchUserSettings, {
      ...action.settings,
    });
    const settings = response.data.data;
    yield put({ type: USER_UPDATE_SETTINGS_SUCCESS, settings });
    yield put({
      type: "SNACKBAR_ADD",
      notificationType: "success",
      content: "Saved settings",
    });
  } catch (error) {
    yield put({
      type: USER_UPDATE_SETTINGS_FAILURE,
      error: createReduxApiError(error as AxiosError),
    });
  }
}

function patchUserSettings(settings: { [key: string]: any }) {
  return axios({
    method: "patch",
    url: API_URL + "/user/settings",
    data: {
      ...settings,
    },
  });
}

// function that makes the api request and returns a Promise for response
function fetchUser(refreshHash?: string) {
  return axios({
    method: "get",
    url:
      API_URL + "/user" + (refreshHash ? "?refresh_hash=" + refreshHash : ""),
  });
}
