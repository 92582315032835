import * as React from "react";
import { cn } from "@sys42/utils";

import styles from "./styles.module.css";

interface TabsContextProps {
  activeTab: string;
  onChangeActiveTab: (tab: string) => void;
  countTabs: number;
}

const TabsContext = React.createContext<TabsContextProps | undefined>(
  undefined,
);

type ContentTabsProps<T extends string> = {
  activeTab: T;
  onChangeActiveTab: (tab: T) => void;
  children: React.ReactNode;
};

interface ContentTabsItemProps {
  count: number;
  value: string;
  children: React.ReactNode;
}

/**
 * ContentTabs renders a container for tab items. It provides the active tab,
 * change callback, and total count via context to its children.
 */
export function ContentTabs<T extends string>(props: ContentTabsProps<T>) {
  const { activeTab, onChangeActiveTab, children } = props;
  const countChildren = React.Children.count(children);

  function handleChangeActiveTab(tab: string) {
    onChangeActiveTab(tab as T);
  }

  return (
    <div role="tablist" className={styles.contentTabs}>
      <TabsContext.Provider
        value={{
          activeTab,
          onChangeActiveTab: handleChangeActiveTab,
          countTabs: countChildren,
        }}
      >
        {children}
      </TabsContext.Provider>
    </div>
  );
}

/**
 * ContentTabs.Item renders a single tab button. It uses context to obtain the
 * active tab, onChange callback, and total item count. It also implements
 * keyboard navigation by determining its position among sibling tab buttons.
 */
// XXX The type for value is now a string but should probably be the same as T of ContentTabs
function ContentTabsItem(props: ContentTabsItemProps) {
  const { count, value, children } = props;
  const context = React.useContext(TabsContext);
  if (!context) {
    throw new Error(
      "ContentTabs.Item must be used within a ContentTabs provider",
    );
  }
  const { activeTab, onChangeActiveTab } = context;
  const isActive = activeTab === value;

  const handleClick = (): void => {
    onChangeActiveTab(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>): void => {
    const currentButton = e.currentTarget;
    const parent = currentButton.parentElement;
    if (!parent) return;
    // Query all sibling tab buttons (each with role="tab")
    const buttons = Array.from(
      parent.querySelectorAll('[role="tab"]'),
    ) as HTMLButtonElement[];
    const index = buttons.indexOf(currentButton);
    let newIndex = index;

    switch (e.key) {
      case "ArrowRight":
        e.preventDefault();
        newIndex = (index + 1) % buttons.length;
        break;
      case "ArrowLeft":
        e.preventDefault();
        newIndex = (index - 1 + buttons.length) % buttons.length;
        break;
      case "Home":
        e.preventDefault();
        newIndex = 0;
        break;
      case "End":
        e.preventDefault();
        newIndex = buttons.length - 1;
        break;
      default:
        return;
    }

    const nextButton = buttons[newIndex];
    if (nextButton) {
      nextButton.focus();
      const newValue = nextButton.getAttribute("data-tab-value");
      if (newValue) {
        onChangeActiveTab(newValue);
      }
    }
  };

  return (
    <button
      type="button"
      role="tab"
      data-tab-value={value}
      aria-selected={isActive}
      tabIndex={isActive ? 0 : -1}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      className={cn(
        styles.contentTabsItem,
        isActive && styles.contentTabsItem_active,
      )}
    >
      <span className={styles.label}>{children}</span>{" "}
      <span className={styles.count}>{count}</span>
    </button>
  );
}

ContentTabs.Item = ContentTabsItem;
