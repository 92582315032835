import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import {
  ButtonLink,
  Card,
  Heading2,
  MainNavigation,
  MainNavigationContent,
  SubNavigation,
  SubNavigationContent,
} from "@/design-system";
import { usePrevious } from "@/helpers-ts";
import Spinner from "../../components/Spinner";
import UpdatePasswordForm from "../../components/UpdatePasswordForm";
import UpdateUserForm from "../../components/UpdateUserForm";
import { useAppDispatch, useAppSelector } from "../../store";
import Navigation from "../Navigation";
import Notifications from "../Notifications";
import SubNavigationAccount from "../SubNavigationAccount";

import styles from "./styles.module.css";

function Account() {
  // Redux
  const dispatch = useAppDispatch();
  const {
    user,
    updateFetching: updateUserFetching,
    updateError: updateUserError,
    updatePasswordFetching: updateUserPasswordFetching,
    updatePasswordError: updateUserPasswordError,
    updatePasswordIsError: updateUserPasswordIsError,
  } = useAppSelector((state) => state.user);

  // Local state
  const [passwordFormVisible, setPasswordFormVisible] = useState(false);
  const prevUpdateUserPasswordFetching = usePrevious(
    updateUserPasswordFetching,
  );

  // On mount: load account
  useEffect(() => {
    dispatch({ type: "ACCOUNT_REQUEST" });
  }, [dispatch]);

  // Watch for password update changes
  useEffect(() => {
    if (
      prevUpdateUserPasswordFetching === true &&
      updateUserPasswordFetching === false
    ) {
      if (!updateUserPasswordIsError) {
        setPasswordFormVisible(false);
      }
    }
  }, [
    updateUserPasswordFetching,
    updateUserPasswordIsError,
    prevUpdateUserPasswordFetching,
  ]);

  function handleUpdateEmail(formData: { email: string; name: string }) {
    dispatch({
      type: "USER_UPDATE_REQUEST",
      email: formData.email,
      name: formData.name,
    });
  }

  function handleUpdatePassword(formData: {
    newPassword: string;
    newPasswordConfirmation: string;
    currentPassword: string;
  }) {
    dispatch({
      type: "USER_UPDATE_PASSWORD_REQUEST",
      newPassword: formData.newPassword,
      newPasswordConfirmation: formData.newPasswordConfirmation,
      currentPassword: formData.currentPassword,
    });
  }

  function handleShowPasswordForm() {
    setPasswordFormVisible(true);
  }

  return (
    <>
      <Helmet>
        <title>Email and Password | Userbrain</title>
      </Helmet>
      <MainNavigation>
        <Navigation />
        <MainNavigationContent>
          <Notifications />
          <SubNavigation>
            <SubNavigationAccount currentNavItem={"account"} />
            <SubNavigationContent>
              <div className={styles.content}>
                {user ? (
                  <Card>
                    <div className={styles.formContent}>
                      <Heading2 className={styles.cardHeading1}>
                        Email and Password
                      </Heading2>
                      <div className={styles.emailForm}>
                        {user && (
                          <UpdateUserForm
                            errorMessage={updateUserError?.message}
                            fieldFeedback={updateUserError?.fieldFeedback}
                            defaultValues={user}
                            saving={updateUserFetching}
                            onSave={handleUpdateEmail}
                          />
                        )}
                      </div>
                      <div className={styles.passwordForm}>
                        {passwordFormVisible ? (
                          <>
                            <h3 className={styles.cardHeading2}>
                              Update your password:
                            </h3>
                            <UpdatePasswordForm
                              errorMessage={updateUserPasswordError?.message}
                              fieldFeedback={
                                updateUserPasswordError?.fieldFeedback
                              }
                              saving={updateUserPasswordFetching}
                              onSave={handleUpdatePassword}
                            />
                          </>
                        ) : (
                          <ButtonLink onClick={handleShowPasswordForm}>
                            Need to change your password?
                          </ButtonLink>
                        )}
                      </div>
                    </div>
                  </Card>
                ) : (
                  <Spinner />
                )}
              </div>
            </SubNavigationContent>
          </SubNavigation>
        </MainNavigationContent>
      </MainNavigation>
    </>
  );
}

export default Account;
