import { cn } from "@sys42/utils";
import { useSelector } from "react-redux";

import Spinner from "@/components/Spinner";
import { TaskResults } from "@/components/TaskResults";
import { selectIsUserGuest } from "@/selectors/user";
import { TestTabCardContentEmptyState } from "../TestTabCardContent/EmptyState";

import styles from "./styles.module.css";

import imgEmptyState from "./empty-state.svg";

export function TestResults(props) {
  const {
    className,
    testId,
    testHasVideos,
    testHasUnfinishedDeliveries,
    ...containerProps
  } = props;

  const fetching = useSelector((state) => state.testReport.fetching);
  const report = useSelector((state) => state.testReport.report);
  const isGuest = useSelector(selectIsUserGuest);
  const maxResponses = report?.task.reduce(
    (acc, task) => (task.responses.length > acc ? task.responses.length : acc),
    0,
  );

  return (
    <div className={cn(className, styles.testResults)} {...containerProps}>
      {fetching && report?.id !== testId && <Spinner />}
      {!fetching && maxResponses === 0 && (
        <div
          className={cn(
            styles.taskResultContainer,
            styles.taskResultContainer_withPadding,
            "card",
          )}
        >
          <TestTabCardContentEmptyState
            EmptyState
            imageSrc={imgEmptyState}
            imageAlt="Man speaking into microphone"
            title={
              testHasVideos
                ? "Reports are only available for newer tests."
                : "Once your videos arrive, you can find your test report here."
            }
          />
        </div>
      )}
      {maxResponses > 0 &&
        report.task.map((task, taskIndex) => (
          <div
            className={cn(styles.taskResultContainer, "card")}
            key={taskIndex}
          >
            <TaskResults
              isGuest={isGuest}
              stepNumber={taskIndex + 1}
              task={task}
              testLanguage={report.language}
              testId={testId}
              testHasUnfinishedDeliveries={testHasUnfinishedDeliveries}
            />
          </div>
        ))}
    </div>
  );
}
