import { ButtonPrimary } from "@/design-system";
import ButtonGroup from "../ButtonGroup";
import { Modal2 } from "../Modal";
import ModalHeader from "../ModalHeader";

import styles from "./styles.module.css";

export default function ModalUnauthorized({ isActive, onClose, ...restProps }) {
  return (
    <Modal2
      isActive={isActive}
      onClose={onClose}
      maxWidth={"29rem"}
      centered
      content={<ModalUnauthorizedContent onClose={onClose} {...restProps} />}
    />
  );
}

function ModalUnauthorizedContent({
  onClose,
  title = "You need access to do this",
  children,
}) {
  return (
    <>
      <ModalHeader heading={<>{title}</>} onClickCloseIcon={onClose} />
      <div className={styles.content}>
        {children}
        <ButtonGroup align={"right"} className={styles.buttonGroup}>
          <ButtonPrimary onClick={onClose}>Got it</ButtonPrimary>
        </ButtonGroup>
      </div>
    </>
  );
}
