import React, { useEffect, useState } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import upperFirst from "lodash/upperFirst";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import {
  ButtonLink,
  ButtonPrimary,
  Card,
  DropDownDialog,
  Heading2,
  MainNavigation,
  MainNavigationContent,
  RadioGroup,
  RadioGroupItem,
  SubNavigation,
  SubNavigationContent,
  Tooltip,
} from "@/design-system";
import {
  roleDescriptionAdmin,
  roleDescriptionCollaborator,
  roleDescriptionGuest,
} from "@/helpers/teamMembers";
import InviteTeamMemberModal from "../../components/InviteTeamMemberModal";
import RemoveTeamMemberModal from "../../components/RemoveTeamMemberModal";
import ResendInviteModal from "../../components/ResendInviteModal";
import RevokeInviteModal from "../../components/RevokeInviteModal";
import Spinner from "../../components/Spinner";
import { UsageBoxTeamMembers } from "../../components/UsageBoxTeamMembers";
import Navigation from "../Navigation";
import Notifications from "../Notifications";
import SubNavigationAccount from "../SubNavigationAccount";

import styles from "./styles.module.css";

const initialInviteTeamMemberModalEmail = "";
const initialInviteTeamMemberModalRole = "collaborator";
const initialInviteTeamMemberModalMessage = "";

export default function AccountTeam() {
  const [inviteTeamMemberModalActive, setInviteTeamMemberModalActive] =
    useState(false);
  const [revokeInviteModalActive, setRevokeInviteModalActive] = useState(false);
  const [revokeInviteModalMember, setRevokeInviteModalMember] = useState(null);
  const [resendInviteModalActive, setResendInviteModalActive] = useState(false);
  const [resendInviteModalMember, setResendInviteModalMember] = useState(null);
  const [removeTeamMemberModalActive, setRemoveTeamMemberModalActive] =
    useState(false);
  const [removeTeamMemberModalMember, setRemoveTeamMemberModalMember] =
    useState(null);
  const [lastChangedRoleMemberId, setLastChangedRoleMemberId] = useState(null);
  const [inviteTeamMemberModalEmail, setInviteTeamMemberModalEmail] = useState(
    initialInviteTeamMemberModalEmail,
  );
  const [inviteTeamMemberModalRole, setInviteTeamMemberModalRole] = useState(
    initialInviteTeamMemberModalRole,
  );
  const [inviteTeamMemberModalMessage, setInviteTeamMemberModalMessage] =
    useState(initialInviteTeamMemberModalMessage);

  const dispatch = useDispatch();
  const {
    user,
    members,
    membersCreateFetching,
    membersCreateError,
    membersCreateToken,
    membersDeleteFetching,
    membersDeleteError,
    membersResendFetching,
    membersResendError,
    membersUpdateFetching,
  } = useSelector((state) => ({
    user: state.user.user,
    members: state.members.members,
    membersFetching: state.members.fetching,
    membersCreateFetching: state.members.createFetching,
    membersCreateError: state.members.createError,
    membersCreateToken: state.members.createToken,
    membersDeleteFetching: state.members.deleteFetching,
    membersDeleteError: state.members.deleteError,
    membersResendFetching: state.members.resendFetching,
    membersResendError: state.members.resendError,
    membersUpdateFetching: state.members.updateFetching,
    membersUpdateError: state.members.updateError,
  }));

  useEffect(() => {
    dispatch({ type: "MEMBERS_REQUEST" });
  }, [dispatch]);

  function handleChangeRole(id, role) {
    dispatch({ type: "MEMBER_UPDATE_REQUEST", id, role });
    setLastChangedRoleMemberId(id);
  }

  function handleClickAddTeamMember() {
    dispatch({ type: "MEMBER_CREATE_RESET" });
    setInviteTeamMemberModalActive(true);
  }

  function handleClickRemoveTeamMember(member) {
    setRemoveTeamMemberModalActive(true);
    setRemoveTeamMemberModalMember(member);
  }

  function handleClickSendInvitation(email, role, message) {
    dispatch({ type: "MEMBER_CREATE_REQUEST", email, role, message });
  }

  function handleClickResendInvitation(member) {
    setResendInviteModalActive(true);
    setResendInviteModalMember(member);
  }

  function handleClickRevokeInvitation(member) {
    setRevokeInviteModalActive(true);
    setRevokeInviteModalMember(member);
  }

  function handleRevokeInvitation(member) {
    dispatch({ type: "MEMBER_DELETE_REQUEST", id: member.id });
  }

  function handleRemoveTeamMember(member) {
    dispatch({ type: "MEMBER_DELETE_REQUEST", id: member.id });
  }

  function handleResendInvitation(member) {
    dispatch({ type: "MEMBER_RESEND_REQUEST", id: member.id });
  }

  function handleCloseInviteTeamMemberModal() {
    setInviteTeamMemberModalActive(false);
  }

  function handleCloseRevokeInviteModal() {
    setRevokeInviteModalActive(false);
  }

  function handleCloseResendInviteModal() {
    setResendInviteModalActive(false);
  }

  function handleCloseRemoveTeamMemberModal() {
    setRemoveTeamMemberModalActive(false);
  }

  function handleChangeInviteTeamMemberModalEmail(e) {
    setInviteTeamMemberModalEmail(e.target.value);
  }

  function handleChangeInviteTeamMemberModalRole(e) {
    setInviteTeamMemberModalRole(e.target.value);
  }

  function handleChangeInviteTeamMemberModalMessage(e) {
    setInviteTeamMemberModalMessage(e.target.value);
  }

  function handleInviteTeamMemberSuccess() {
    setInviteTeamMemberModalEmail(initialInviteTeamMemberModalEmail);
    setInviteTeamMemberModalRole(initialInviteTeamMemberModalRole);
    setInviteTeamMemberModalMessage(initialInviteTeamMemberModalMessage);
  }

  const teamMembers =
    members?.filter((member) => member.invitation_accepted === true) ?? [];
  const invitations =
    members?.filter((member) => member.invitation_accepted !== true) ?? [];
  const guests =
    members?.filter(
      (member) =>
        member.invitation_accepted === true && member.role === "guest",
    ) ?? [];
  const collaborators =
    members?.filter(
      (member) =>
        member.invitation_accepted === true && member.role === "collaborator",
    ) ?? [];
  const admins =
    members?.filter(
      (member) =>
        member.invitation_accepted === true && member.role === "admin",
    ) ?? [];
  const guestInvitations =
    members?.filter(
      (member) =>
        member.invitation_accepted !== true && member.role === "guest",
    ) ?? [];
  const collaboratorInvitations =
    members?.filter(
      (member) =>
        member.invitation_accepted !== true && member.role === "collaborator",
    ) ?? [];
  const adminInvitations =
    members?.filter(
      (member) =>
        member.invitation_accepted !== true && member.role === "admin",
    ) ?? [];

  const maxGuests = user?.features.limit_guests;
  const usedGuests = guests.length + guestInvitations.length;

  const maxCollaborators = user?.features.limit_collaborators;
  const usedCollaborators = Math.min(
    collaborators.length + collaboratorInvitations.length,
    maxCollaborators,
  );

  const maxAdmins = user?.features.limit_admins;
  const usedAdmins = Math.min(
    admins.length + adminInvitations.length,
    maxAdmins,
  );

  return (
    <>
      <Helmet>
        <title>Team Members | Userbrain</title>
      </Helmet>
      <MainNavigation>
        <Navigation />
        <MainNavigationContent>
          <Notifications />
          <SubNavigation>
            <SubNavigationAccount currentNavItem={"team"} />
            <SubNavigationContent>
              <div className={styles.content}>
                <UsageBoxTeamMembers
                  usedCollaborators={usedCollaborators}
                  maxCollaborators={maxCollaborators}
                  usedAdmins={usedAdmins}
                  maxAdmins={maxAdmins}
                />
                {members === null || user === null ? (
                  <Spinner />
                ) : (
                  <>
                    <Card>{renderTeamMembers()}</Card>
                    <Card>{renderPendingInvites()}</Card>
                  </>
                )}
              </div>
            </SubNavigationContent>
          </SubNavigation>
        </MainNavigationContent>
      </MainNavigation>
      <InviteTeamMemberModal
        isActive={inviteTeamMemberModalActive}
        onClose={handleCloseInviteTeamMemberModal}
        fetching={membersCreateFetching}
        error={membersCreateError}
        token={membersCreateToken}
        onSendInvitation={handleClickSendInvitation}
        onSuccess={handleInviteTeamMemberSuccess}
        onChangeEmail={handleChangeInviteTeamMemberModalEmail}
        onChangeRole={handleChangeInviteTeamMemberModalRole}
        onChangeMessage={handleChangeInviteTeamMemberModalMessage}
        email={inviteTeamMemberModalEmail}
        role={inviteTeamMemberModalRole}
        message={inviteTeamMemberModalMessage}
        usedGuests={usedGuests}
        maxGuests={maxGuests}
        usedCollaborators={usedCollaborators}
        maxCollaborators={maxCollaborators}
        usedAdmins={usedAdmins}
        maxAdmins={maxAdmins}
      />
      <RevokeInviteModal
        isActive={revokeInviteModalActive}
        member={revokeInviteModalMember}
        onClose={handleCloseRevokeInviteModal}
        onRevoke={handleRevokeInvitation}
        fetching={membersDeleteFetching}
        error={membersDeleteError}
      />
      <ResendInviteModal
        isActive={resendInviteModalActive}
        member={resendInviteModalMember}
        onClose={handleCloseResendInviteModal}
        onResend={handleResendInvitation}
        fetching={membersResendFetching}
        error={membersResendError}
      />
      <RemoveTeamMemberModal
        isActive={removeTeamMemberModalActive}
        member={removeTeamMemberModalMember}
        onClose={handleCloseRemoveTeamMemberModal}
        onRemove={handleRemoveTeamMember}
        fetching={membersDeleteFetching}
        error={membersDeleteError}
      />
    </>
  );

  function renderTeamMembers() {
    return (
      <div className={styles.formContent}>
        <Heading2 className={styles.cardHeading1}>Team Members</Heading2>
        <div className={styles.teamMembers}>
          <div className={styles.teamMember}>
            <div
              className={styles.teamMemberEmail}
              title={`${user?.subscription_owner}`}
            >
              {user?.subscription_owner}
            </div>
            <div className={styles.accountOwnerTag}>
              <span className={styles.accountOwnerLabel}>
                Account Owner
                {user?.email === user?.subscription_owner && <> (me)</>}
                &nbsp;
              </span>
              <Tooltip
                content={
                  <>
                    Only the account owner can:
                    <ul className={styles.accountOwnerCanList}>
                      <li>
                        Update billing information and payment card details
                      </li>
                      <li>Cancel current subscription</li>
                    </ul>
                  </>
                }
              />
            </div>
          </div>
          {teamMembers.map((teamMember) => renderTeamMember(teamMember))}
        </div>
        <ButtonPrimary
          onClick={handleClickAddTeamMember}
          className={styles.addTeamMember}
        >
          Add Team Member
        </ButtonPrimary>
      </div>
    );
  }

  function renderPendingInvites() {
    return (
      <div className={styles.formContent}>
        <Heading2 className={styles.headingInvites}>Pending invites</Heading2>
        {invitations.length > 0 ? (
          <div className={styles.invitations}>
            {invitations.map((invitation) => (
              <div className={styles.invitation} key={invitation.id}>
                {invitation.invitation_email}{" "}
                <span className={styles.invitationRole}>
                  {upperFirst(invitation.role)}
                </span>
                <div className={styles.invitationActions}>
                  <ButtonLink
                    onClick={() => handleClickResendInvitation(invitation)}
                  >
                    Resend invite
                  </ButtonLink>
                  <ButtonLink
                    onClick={() => handleClickRevokeInvitation(invitation)}
                  >
                    Revoke invite
                  </ButtonLink>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.invitationsEmptyState}>
            No pending invitations.
          </div>
        )}
      </div>
    );
  }

  function renderTeamMember(teamMember) {
    return (
      <div className={styles.teamMember} key={teamMember.id}>
        <div
          className={styles.teamMemberEmail}
          title={teamMember.invitation_email}
        >
          {teamMember.invitation_email}
        </div>
        {user?.email === teamMember.invitation_email ? (
          <div className={styles.accountOwnerLabel}>
            {upperFirst(teamMember.role)}
            {user?.email === teamMember.invitation_email && <> (me)</>}
          </div>
        ) : (
          <DropDownDialog
            className={styles.dropDownRole}
            label={
              lastChangedRoleMemberId === teamMember.id &&
              membersUpdateFetching ? (
                <>Saving…</>
              ) : teamMember.active ? (
                upperFirst(teamMember.role)
              ) : (
                <span className={styles.overLimit}>Over limit</span>
              )
            }
            align={"left"}
            appearance={"select"}
            closeOnClick={true}
          >
            <div className={styles.dropDownRoleContent}>
              <RadioGroup
                className={styles.radioGroupRole}
                value={teamMember.role}
                // disabled={true}
                onChange={(e) =>
                  handleChangeRole(teamMember.id, e.target.value)
                }
              >
                <RadioGroupItem
                  className={styles.radioGroupItemRole}
                  value={"guest"}
                >
                  <strong>Guest</strong>{" "}
                  <span className={styles.usage}>(unlimited)</span>
                  <div className={styles.roleDescription}>
                    {roleDescriptionGuest}
                  </div>
                </RadioGroupItem>
                <RadioGroupItem
                  className={styles.radioGroupItemRole}
                  value={"collaborator"}
                  disabled={
                    usedCollaborators >= maxCollaborators &&
                    teamMember.role !== "collaborator"
                  }
                >
                  <strong>Collaborator</strong>{" "}
                  <span className={styles.usage}>
                    ({usedCollaborators} of {maxCollaborators} used)
                  </span>
                  <div className={styles.roleDescription}>
                    {roleDescriptionCollaborator}
                  </div>
                </RadioGroupItem>
                <RadioGroupItem
                  className={styles.radioGroupItemRole}
                  value={"admin"}
                  disabled={
                    usedAdmins >= maxAdmins && teamMember.role !== "admin"
                  }
                >
                  <strong>Admin</strong>{" "}
                  <span className={styles.usage}>
                    ({usedAdmins} of {maxAdmins} used)
                  </span>
                  <div className={styles.roleDescription}>
                    {roleDescriptionAdmin}
                  </div>
                </RadioGroupItem>
              </RadioGroup>
              <hr className={styles.dropDownItemsDivider} />
              <button
                className={styles.teamMemberRemove}
                onClick={() => handleClickRemoveTeamMember(teamMember)}
              >
                <FontAwesomeIcon
                  className={styles.teamMemberRemoveIcon}
                  icon={solid("trash")}
                  fixedWidth
                />{" "}
                Remove
              </button>
            </div>
          </DropDownDialog>
        )}
      </div>
    );
  }
}
