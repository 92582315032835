import {
  configureStore,
  DevToolsEnhancerOptions,
  Middleware,
} from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
/**
 * Create the store with dynamic reducers
 */
import createSagaMiddleware from "redux-saga";

import createReducer, { reducers } from "./reducers";
import { rootSaga } from "./sagas";
import { devToolsOptions } from "./store-devtools";

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

const analyticsMiddleware: Middleware = () => (next) => (action) => {
  switch (action.type) {
    case "TEST_SAVE_SUCCESS":
    case "DELIVERY_CREATE_SUCCESS":
    case "SIGN_UP_SUCCESS":
    case "ANALYTICS_CONVERSION":
    case "ANALYTICS_TEST_CREATED":
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: action.type,
        payload: action.gtmMeta,
      });
      break;
    default:
      break;
  }
  return next(action);
};

let devTools: DevToolsEnhancerOptions | boolean;

if (process.env.NODE_ENV !== "production") {
  devTools = devToolsOptions;
} else {
  devTools = false;
}

function configureAppStore() {
  // create the saga middleware
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: createReducer(),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(sagaMiddleware, analyticsMiddleware),
    devTools,
  });

  // run the saga
  sagaMiddleware.run(rootSaga);

  return store;
}

const store = configureAppStore();
export { store };

type GetStateFromReducers<T> = T extends (...args: any[]) => infer Return
  ? Return
  : T extends Record<any, any>
    ? {
        [K in keyof T]: GetStateFromReducers<T[K]>;
      }
    : T;
export type RootState = GetStateFromReducers<typeof reducers>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
