import { VideoStar } from "@/components/VideoStar";
import { useAppDispatch, useAppSelector } from "@/store";
import { Video } from "../types";

interface SidebarVideoStarProps {
  video: Video;
  isReadOnly?: boolean;
}

/**
 *
 * Menu
 */
export function SidebarVideoStar({ video, isReadOnly }: SidebarVideoStarProps) {
  const dispatch = useAppDispatch();

  const starFetching = useAppSelector(
    (state) => state.videos.fetchingStar.indexOf(video.id) !== -1,
  );
  const unstarFetching = useAppSelector(
    (state) => state.videos.fetchingUnstar.indexOf(video?.id) !== -1,
  );
  const isStarred = (video.starred || starFetching) && !unstarFetching;

  function handleClickVideoStar() {
    if (isStarred) {
      dispatch({ type: "VIDEOS_UNSTAR_REQUEST", id: video?.id });
    } else {
      dispatch({ type: "VIDEOS_STAR_REQUEST", id: video?.id });
    }
  }

  if (!isStarred && isReadOnly) {
    return null;
  }

  return (
    <VideoStar
      style={{ marginLeft: "auto" }}
      isStarred={isStarred}
      isReadOnly={isReadOnly}
      onClick={!isReadOnly ? handleClickVideoStar : undefined}
    />
  );
}
