import { DevToolsEnhancerOptions } from "@reduxjs/toolkit";

const appupdateRequiredAction = () => ({ type: "APPUPDATE_REQUIRED" });
const welcomeMessageShowAction = () => ({
  type: "WELCOME_MESSAGE_SHOW",
  welcomeMessageType: "freetrial",
});

export const devToolsOptions: DevToolsEnhancerOptions = {
  /* Note: This will also be bundled in the production build! */
  actionCreators: {
    appupdateRequiredAction,
    welcomeMessageShowAction,
  },
};
