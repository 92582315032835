export enum VideoTabName {
  Details,
  Task,
  Notes,
  Transcript,
}

// WARNING this is incomplete
export type TaskStep = {
  id: number;
  timestampStart: number;
  videoEndsAfterThisStep?: boolean;
  timestampCompleted: number;
  type:
    | "transcript"
    | "task"
    | "single_choice"
    | "multiple_choice"
    | "rating_scale"
    | "redirect"
    | "written_response";
  task: string;
  response?: {
    skipped?: boolean;
    completed?: boolean;
    choice: number;
    rating: number;
    response: "";
  };
  options: {
    url: string;
    answers?: string[];
  };
  transcript: string;
};

export type VideoNote = {
  id: number;
  automated_insight: boolean;
  timestamp: number;
  timestamp_end?: number | null; // The api might return null
  edited_at: Date | null;
  user: string;
  is_mine: boolean;
  note: string | null; // The api might return null
  editable: boolean;
  deletable: boolean;
  timestampUserInput?: string | null;
};

export type VideoClip = VideoNote & {
  shared_hash: string | false;
  shared: boolean;
  status: string;
};

export type Video = {
  id: number;
  test_id: string;
  test_url: string;
  created_at: Date;
  title: string;
  type: "";
  rating: number;
  starred: 0 | 1;
  duration: number;
  location: "";
  ai_status: "complete" | "available" | "processing";
  sources: "";
  preview?: {
    vtt: "";
    image: "";
  };
  tester: {
    type: "invitation";
    email: string;
    name: string;
  };
  poster: "";
  notes: VideoNote[];
  clips: VideoClip[];
  transcript_vtt_url: "";
  download_video_url: string;
  download_transcript_url: string;
  download_notes_pdf_url: string;
  download_notes_csv_url: string;
  transcript: unknown[];
  shared: boolean;
  new: 0 | 1;
  screener: {};
  shared_hash: string; // TODO add template literal
  device?: {
    type: "mobile" | "desktop" | "tablet";
    details: string;
    os: string;
  };
  problem_reported: boolean;
  hash: string;
};
