import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  ButtonPrimary,
  ErrorMessage,
  FieldHelp,
  FormField,
  InputPassword,
  Stack,
} from "@/design-system";

class UpdatePasswordForm extends Component {
  static defaultProps = { defaultValues: {} };

  constructor(props) {
    super(props);
    const { defaultValues } = props;
    this.state = {
      currentPassword: defaultValues.currentPassword
        ? defaultValues.currentPassword
        : "",
      newPassword: defaultValues.newPassword ? defaultValues.newPassword : "",
      newPasswordConfirmation: defaultValues.newPasswordConfirmation
        ? defaultValues.newPasswordConfirmation
        : "",
    };
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { currentPassword, newPassword, newPasswordConfirmation } =
      this.state;
    if (typeof this.props.onSave === "function") {
      this.props.onSave({
        currentPassword,
        newPassword,
        newPasswordConfirmation,
      });
    }
  };

  render() {
    const { errorMessage, fieldFeedback, saving } = this.props;
    return (
      <form onSubmit={this.onSubmit}>
        <Stack>
          {errorMessage && <ErrorMessage message={errorMessage} />}

          <FormField
            errorMessage={fieldFeedback?.current_password}
            label={"Current password"}
          >
            <InputPassword
              withShowPasswordToggle={true}
              value={this.state.currentPassword}
              onChange={(e) =>
                this.setState({ currentPassword: e.target.value })
              }
            />
          </FormField>
          <FormField
            errorMessage={fieldFeedback?.new_password}
            label={"Your new password"}
          >
            <InputPassword
              withShowPasswordToggle={true}
              value={this.state.newPassword}
              onChange={(e) => this.setState({ newPassword: e.target.value })}
            />
            <FieldHelp>
              Your password must contain at least 8 characters
            </FieldHelp>
          </FormField>
          <FormField
            errorMessage={fieldFeedback?.new_password_confirmation}
            label={"Repeat your new password"}
          >
            <InputPassword
              withShowPasswordToggle={true}
              value={this.state.newPasswordConfirmation}
              onChange={(e) =>
                this.setState({ newPasswordConfirmation: e.target.value })
              }
            />
          </FormField>
          {saving ? (
            <ButtonPrimary disabled>Updating password…</ButtonPrimary>
          ) : (
            <ButtonPrimary type={"submit"}>Update password</ButtonPrimary>
          )}
        </Stack>
      </form>
    );
  }
}

UpdatePasswordForm.propTypes = {
  onSave: PropTypes.func,
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([false]),
  ]),
  defaultValues: PropTypes.object.isRequired,
};

export default UpdatePasswordForm;
