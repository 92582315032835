import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  ButtonPrimary,
  ErrorMessage,
  FieldHelp,
  FormField,
  InputText,
  Stack,
} from "@/design-system";

import styles from "./styles.module.css";

class UpdateUserForm extends Component {
  static defaultProps = { fieldFeedback: {}, defaultValues: {} };

  constructor(props) {
    super(props);
    const { defaultValues } = props;
    this.state = {
      email: defaultValues.email ? defaultValues.email : "",
      name: defaultValues.name ? defaultValues.name : "",
    };
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (typeof this.props.onSave === "function") {
      this.props.onSave({ email: this.state.email, name: this.state.name });
    }
  };

  render() {
    const { errorMessage, fieldFeedback, saving } = this.props;

    return (
      <form onSubmit={this.onSubmit}>
        <Stack>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <FormField
            errorMessage={fieldFeedback.email ? fieldFeedback.email : false}
            label={"Your email address"}
          >
            <InputText
              below={
                <FieldHelp>
                  The email address you use to log in to Userbrain
                </FieldHelp>
              }
              value={this.state.email}
              onChange={(e) => this.setState({ email: e.target.value })}
            />
          </FormField>
          <FormField
            errorMessage={fieldFeedback.name ? fieldFeedback.name : false}
            label={"Your name"}
          >
            <InputText
              placeholder={"Optional"}
              below={
                <FieldHelp>
                  Will be displayed next to comments instead of your email
                </FieldHelp>
              }
              value={this.state.name}
              onChange={(e) => this.setState({ name: e.target.value })}
            />
          </FormField>
          {saving ? (
            <ButtonPrimary className={styles.submitButton} disabled>
              Saving changes…
            </ButtonPrimary>
          ) : (
            <ButtonPrimary className={styles.submitButton} type={"submit"}>
              Save changes
            </ButtonPrimary>
          )}
        </Stack>
      </form>
    );
  }
}

UpdateUserForm.propTypes = {
  onSave: PropTypes.func,
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([false]),
  ]),
  fieldFeedback: PropTypes.object.isRequired,
  defaultValues: PropTypes.object.isRequired,
};

export default UpdateUserForm;
