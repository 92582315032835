import React, { Component } from "react";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@sys42/utils";

import styles from "./styles.module.css";

class VideoRating extends Component {
  constructor(props) {
    super(props);
    this.state = { hoveredRating: 0, justRated: false };
  }

  onClick = (rating) => {
    if (typeof this.props.onClick === "function") {
      this.props.onClick(Math.max(1, Math.min(5, rating)));
    }
    this.setState({ justRated: rating });
  };

  render() {
    const { hoveredRating, justRated } = this.state;
    const { rating, isReadOnly, className } = this.props;
    const ratingHtml = [];
    let visibleRating = rating;
    let label;

    if (isReadOnly) {
      if (rating > 0) {
        ratingHtml.push(
          [...Array(5)].map((_, i) => (
            <span key={i} className={styles.star}>
              {i + 1 <= rating ? (
                <FontAwesomeIcon icon={solid("star")} />
              ) : (
                <FontAwesomeIcon icon={regular("star")} />
              )}
            </span>
          )),
        );
      } else {
        label = "No rating yet";
      }
    } else {
      if (rating === 0) {
        label = "Please rate this tester";
      } else if (justRated) {
        if (justRated === 5) {
          label = "Perfect!";
        } else {
          label = "Thanks!";
        }
      }

      if (!justRated) {
        if (hoveredRating > 0) {
          visibleRating = hoveredRating;
          label = "Click to rate";
        }
      }

      for (let i = 1; i <= 5; i++) {
        let icon;
        if (i > visibleRating) {
          icon = <FontAwesomeIcon icon={regular("star")} key={i} />;
        } else {
          icon = <FontAwesomeIcon icon={solid("star")} key={i} />;
        }
        ratingHtml.push(
          <span
            key={i}
            className={styles.star}
            onMouseOver={() => {
              this.setState({
                hoveredRating: i,
                justRated:
                  i === this.state.justRated ? this.state.justRated : false,
              });
            }}
            onClick={() => this.onClick(i)}
          >
            {icon}
          </span>,
        );
      }
    }

    return (
      <div
        className={cn(
          styles.videoRating,
          isReadOnly && styles.videoRating_isReadOnly,
          className,
        )}
        onMouseLeave={() => this.setState({ hoveredRating: 0 })}
      >
        {ratingHtml} <span className={styles.label}>{label}</span>
      </div>
    );
  }
}

export default VideoRating;
