import { useEffect, useState } from "react";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn, usePrevious } from "@sys42/utils";

import styles from "./styles.module.css";

type VideoStarProps = {
  className?: string;
  isStarred: boolean;
  isReadOnly?: boolean;
  color?: string;
  style?: React.CSSProperties;
  onClick?: (isStarred: boolean) => void;
};

export function VideoStar({
  className,
  isStarred,
  isReadOnly,
  style,
  onClick,
}: VideoStarProps) {
  const [isGotStarred, setIsGotStarred] = useState(false);
  const prevIsStarred = usePrevious(isStarred);

  useEffect(() => {
    if (isStarred === true && prevIsStarred === false) {
      setIsGotStarred(true);
    } else if (isStarred === false) {
      setIsGotStarred(false);
    }
  }, [isStarred, prevIsStarred]);

  function handleClick() {
    if (typeof onClick === "function") {
      onClick(!isStarred);
    }
  }

  return (
    <button
      className={cn(
        className,
        styles.videoStar,
        isGotStarred && styles.videoStar_isGotStarred,
        isReadOnly && styles.videoStar_isReadOnly,
      )}
      style={style}
      onClick={handleClick}
    >
      {isStarred ? (
        <FontAwesomeIcon icon={solid("heart")} fixedWidth />
      ) : (
        <FontAwesomeIcon icon={regular("heart")} fixedWidth />
      )}
    </button>
  );
}
