import { useNavigate } from "react-router-dom";

import ModalUnauthorized from "@/components/ModalUnauthorized";
import { ButtonPrimary, Stack } from "@/design-system";
import { selectIsUserGuest } from "@/selectors/user";
import { useAppSelector } from "@/store";
import { FullscreenModal2 } from "../../components/Modal";

import styles from "./styles.module.css";

import { ReactComponent as SvgIcon180App } from "./icons/180px/app-testing.svg";
import { ReactComponent as SvgIcon180Prototype } from "./icons/180px/prototype-testing.svg";
import { ReactComponent as SvgIcon180Website } from "./icons/180px/website-testing.svg";

type CreateTestModalProps = {
  templateId: string;
  isActive: boolean;
  onClose: () => void;
};

export function CreateTestModal({
  templateId,
  isActive,
  onClose,
}: CreateTestModalProps) {
  const isUserGuest = useAppSelector(selectIsUserGuest);

  if (isUserGuest) {
    return (
      <ModalUnauthorized onClose={onClose} isActive={isActive}>
        You’re a Guest, so you can’t create tests or order testers.
        <br />
        <br />
        Ask an Admin to give you access.
      </ModalUnauthorized>
    );
  }

  return (
    <FullscreenModal2
      onClose={onClose}
      isActive={isActive}
      content={
        <CreateTestModalContent onClose={onClose} templateId={templateId} />
      }
    />
  );
}

function CreateTestModalContent(props: Omit<CreateTestModalProps, "isActive">) {
  const { onClose, templateId } = props;

  const navigate = useNavigate();

  function navigateToCreateTest(type: "website" | "app" | "prototype") {
    if (templateId) {
      navigate(`/test/create/${type}/template/${templateId}`);
    } else {
      navigate(`/test/create/${type}`);
    }
  }

  function handleClickWebsite() {
    navigateToCreateTest("website");
    onClose();
  }

  function handleClickApp() {
    navigateToCreateTest("app");
    onClose();
  }

  function handleClickPrototype() {
    navigateToCreateTest("prototype");
    onClose();
  }

  return (
    <div className={styles.content}>
      <Stack spacing="xlarge">
        <h1 className={styles.title}>What do you want to test?</h1>
        <div className={styles.typeCardsContainer}>
          <div className={styles.typeCard}>
            <SvgIcon180Website className={styles.icon180} />
            <div>
              <h2>Website</h2>
              <p>Test websites, web apps, online shops and any other link.</p>
            </div>
            <ButtonPrimary
              className={styles.button}
              onClick={handleClickWebsite}
              fullWidth
              big
            >
              Create a website test
            </ButtonPrimary>
          </div>
          <div className={styles.typeCard}>
            <SvgIcon180App className={styles.icon180} />
            <div>
              <h2>Mobile App</h2>
              <p>Test mobile apps or games for Android and iOS devices.</p>
            </div>
            <ButtonPrimary
              className={styles.button}
              onClick={handleClickApp}
              fullWidth
              big
            >
              Create a mobile app test
            </ButtonPrimary>
          </div>
          <div className={styles.typeCard}>
            <SvgIcon180Prototype className={styles.icon180} />
            <div>
              <h2>Prototype</h2>
              <p>Test Figma, InVision, AdobeXD and other online prototypes.</p>
            </div>
            <ButtonPrimary
              className={styles.button}
              onClick={handleClickPrototype}
              fullWidth
              big
            >
              Create a prototype test
            </ButtonPrimary>
          </div>
        </div>
      </Stack>
    </div>
  );
}
