import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import {
  ButtonPrimary,
  FormField,
  RadioGroup,
  RadioGroupItem,
  Stack,
  ToggleButton,
} from "@/design-system";
import {
  Card,
  Heading2,
  MainNavigation,
  MainNavigationContent,
  SubNavigation,
  SubNavigationContent,
} from "@/design-system";
import { useAppDispatch, useAppSelector } from "../../store";
import Navigation from "../Navigation";
import Notifications from "../Notifications";
import SubNavigationAccount from "../SubNavigationAccount";

import styles from "./styles.module.css";

type AccountNotificationsProps = {};

export function AccountNotifications(props: AccountNotificationsProps) {
  const dispatch = useAppDispatch();

  const userSettings = useAppSelector((state) => state.user.user?.settings);
  const { updateSettingsFetching } = useAppSelector((state) => state.user);

  const [notificationsTesters, setNotificationsTesters] = useState(
    userSettings?.notifications?.testers || "ordered",
  );
  const [notificationsSessions, setNotificationsSessions] = useState(
    userSettings?.notifications?.sessions || "after_each",
  );
  // New state for email notifications toggle
  const [notificationsEnabled, setNotificationsEnabled] = useState(
    userSettings?.notifications?.enabled || false,
  );

  useEffect(() => {
    dispatch({ type: "ACCOUNT_REQUEST" });
  }, [dispatch]);

  function handleNotificationsTestersChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    setNotificationsTesters(event.target.value);
  }

  function handleNotificationsSessionsChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    setNotificationsSessions(event.target.value);
  }

  // New event handler for toggle change
  function handleChangeEmailNotificationsToggle(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    setNotificationsEnabled(event.target.checked);
  }

  function handleSaveNotificationSettings() {
    dispatch({
      type: "USER_UPDATE_SETTINGS_REQUEST",
      settings: {
        notifications: {
          testers: notificationsTesters,
          sessions: notificationsSessions,
          enabled: notificationsEnabled, // include toggle setting
        },
      },
    });
  }

  return (
    <>
      <Helmet>
        <title>Account Notifications | Userbrain</title>
      </Helmet>
      <MainNavigation>
        <Navigation />
        <MainNavigationContent>
          <Notifications />
          <SubNavigation>
            <SubNavigationAccount currentNavItem={"notifications"} />
            <SubNavigationContent>
              <div className={styles.content}>
                <Card>
                  <Stack className={styles.formContent}>
                    <Heading2>Notifications</Heading2>

                    <ToggleButton
                      onChange={handleChangeEmailNotificationsToggle}
                      checked={notificationsEnabled}
                    >
                      Enable email notifications
                    </ToggleButton>

                    <FormField label={"Get notified about:"}>
                      <RadioGroup
                        value={notificationsTesters}
                        onChange={handleNotificationsTestersChange}
                      >
                        <RadioGroupItem value={"ordered"}>
                          Testers I've ordered
                        </RadioGroupItem>
                        <RadioGroupItem value={"all"}>
                          All Testers
                        </RadioGroupItem>
                      </RadioGroup>
                    </FormField>
                    <FormField label={"When should you be notified?"}>
                      <RadioGroup
                        value={notificationsSessions}
                        onChange={handleNotificationsSessionsChange}
                      >
                        <RadioGroupItem value={"after_each"}>
                          After each session
                        </RadioGroupItem>
                        <RadioGroupItem value={"when_complete"}>
                          When all sessions are complete
                        </RadioGroupItem>
                      </RadioGroup>
                    </FormField>
                    <ButtonPrimary
                      disabled={updateSettingsFetching}
                      onClick={handleSaveNotificationSettings}
                    >
                      {updateSettingsFetching
                        ? "Saving changes…"
                        : "Save changes"}
                    </ButtonPrimary>
                  </Stack>
                </Card>
              </div>
            </SubNavigationContent>
          </SubNavigation>
        </MainNavigationContent>
      </MainNavigation>
    </>
  );
}

export default AccountNotifications;
