import React from "react";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import startCase from "lodash/startCase";
import { Link } from "react-router-dom";

import { OverflowMenu, OverflowMenuItem } from "@/design-system";
import { selectIsUserGuest } from "@/selectors/user";
import { useAppSelector } from "@/store";
import { getWordingForTestType, SHOW_DEV_TOOLS } from "../../helpers";

import styles from "./styles.module.css";

import imageLoadingGif from "./Spin-1s-38px.gif";

dayjs.extend(relativeTime);

export default function Test(props) {
  const {
    onClickGetTesters,
    onClickDuplicate,
    onClickArchive,
    onClickUnarchive,
    onClickDelete,
    onClickRequestTestTest,
    onClickRequestTestingTestTest,
    onClickRequestInvitationTestTest,
    countVideos,
    countNewVideos,
    countVideosOpen,
    status,
    title,
    testType,
    archived,
    invitation,
    testId,
    hasRepeatDeliveries,
    isArchivable,
    isDeleteable,
    isOwnerOrAdmin,
  } = props;

  const isGuest = useAppSelector(selectIsUserGuest);

  function handleClickGetTesters() {
    onClickGetTesters();
  }

  function handleClickDuplicate() {
    onClickDuplicate();
  }

  function handleClickArchive() {
    onClickArchive();
  }

  function handleClickUnarchive() {
    onClickUnarchive();
  }

  function handleClickDelete() {
    onClickDelete();
  }

  function handleClickRequestTestTest() {
    onClickRequestTestTest("delivered");
  }

  function handleClickRequestStartedTestTest() {
    onClickRequestTestingTestTest("started");
  }

  function handleClickRequestTestingTestTest() {
    onClickRequestTestingTestTest("testing");
  }

  function handleClickRequestPausedTestTest() {
    onClickRequestTestingTestTest("paused");
  }

  function handleClickRequestUploadingTestTest() {
    onClickRequestTestingTestTest("uploading");
  }

  function handleClickRequestScreeningTestTest() {
    onClickRequestTestingTestTest("screening");
  }

  function handleClickRequestPendingProcessingTestTest() {
    onClickRequestTestTest("pending", true);
  }

  function handleClickRequestPendingTestTest() {
    onClickRequestTestTest("pending", false);
  }

  function handleClickRequestInvitationTestTest() {
    onClickRequestInvitationTestTest();
  }

  let statusSymbol;
  if (archived) {
    statusSymbol = (
      <div className={`${styles.statusSymbol} ${styles.statusSymbolArchive}`}>
        <FontAwesomeIcon icon={regular("archive")} />
      </div>
    );
  } else {
    switch (status) {
      case "running":
        statusSymbol = (
          <div className={`${styles.statusSymbol}`}>
            <img src={imageLoadingGif} alt="running" height={19} width={19} />
          </div>
        );
        break;
      case "draft":
        statusSymbol = (
          <div className={`${styles.statusSymbol} ${styles.statusSymbolDraft}`}>
            <FontAwesomeIcon icon={regular("file-lines")} />
          </div>
        );
        break;
      default:
        statusSymbol = (
          <div className={`${styles.statusSymbol} ${styles.statusSymbolCheck}`}>
            <FontAwesomeIcon icon={solid("check")} />
          </div>
        );
        break;
    }
  }

  return (
    <div className={`card ${styles.test}`} data-testid={`test-card-${testId}`}>
      <Link className={styles.link} to={"/test/" + testId} />

      {statusSymbol}

      <div className={styles.testContent}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.additionalInfos}>
          <div className={styles.additionalInfosTesting}>
            {
              <div className={styles.additionalInfosTestingItem}>
                {startCase(getWordingForTestType(testType))}
              </div>
            }
            {status === "draft" && (
              <div className={styles.additionalInfosTestingItem}>Draft</div>
            )}
            {status !== "draft" && (
              <div className={styles.additionalInfosTestingItem}>
                {countVideos} of {countVideos + countVideosOpen} testers
              </div>
            )}
            {hasRepeatDeliveries && (
              <div className={styles.additionalInfosTestingItem}>
                Repeats automatically
              </div>
            )}
            {invitation.active && (
              <div className={styles.additionalInfosTestingItem}>
                Invitation link active
              </div>
            )}
          </div>
          {countNewVideos > 0 && (
            <div className={styles.additionalInfosNewSession}>
              {countNewVideos} new session{countNewVideos !== 1 && "s"}
            </div>
          )}
        </div>
      </div>

      {!isGuest && (
        <OverflowMenu className={styles.overflowMenu}>
          {!archived && (
            <OverflowMenuItem onClick={handleClickGetTesters}>
              Get testers
            </OverflowMenuItem>
          )}
          <OverflowMenuItem linkTo={"/test/" + testId + "/setup"}>
            Edit
          </OverflowMenuItem>
          <OverflowMenuItem onClick={handleClickDuplicate}>
            Duplicate
          </OverflowMenuItem>
          {!archived && isArchivable && (
            <OverflowMenuItem onClick={handleClickArchive}>
              Archive
            </OverflowMenuItem>
          )}
          {!!archived && (
            <OverflowMenuItem onClick={handleClickUnarchive}>
              Unarchive
            </OverflowMenuItem>
          )}
          {isDeleteable && isOwnerOrAdmin && (
            <OverflowMenuItem onClick={handleClickDelete} caution>
              Delete
            </OverflowMenuItem>
          )}
          {SHOW_DEV_TOOLS && (
            <>
              <OverflowMenuItem onClick={handleClickRequestTestTest}>
                Request a test test
              </OverflowMenuItem>
              <OverflowMenuItem onClick={handleClickRequestScreeningTestTest}>
                Request a screening test test
              </OverflowMenuItem>
              <OverflowMenuItem onClick={handleClickRequestStartedTestTest}>
                Request a started test test
              </OverflowMenuItem>
              <OverflowMenuItem onClick={handleClickRequestTestingTestTest}>
                Request a testing test test
              </OverflowMenuItem>
              <OverflowMenuItem onClick={handleClickRequestPausedTestTest}>
                Request a paused test test
              </OverflowMenuItem>
              <OverflowMenuItem onClick={handleClickRequestUploadingTestTest}>
                Request a uploading test test
              </OverflowMenuItem>
              <OverflowMenuItem onClick={handleClickRequestPendingTestTest}>
                Request a pending test test
              </OverflowMenuItem>
              <OverflowMenuItem
                onClick={handleClickRequestPendingProcessingTestTest}
              >
                Request a pending processing test test
              </OverflowMenuItem>
              <OverflowMenuItem onClick={handleClickRequestInvitationTestTest}>
                Request a invitation test test
              </OverflowMenuItem>
            </>
          )}
        </OverflowMenu>
      )}
    </div>
  );
}
