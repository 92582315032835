import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Button,
  ButtonIcon,
  ButtonPrimary,
  OverflowMenu,
  OverflowMenuItem,
  Tooltip,
} from "@/design-system";
import { textCantEditTest } from "@/helpers";

import styles from "./styles.module.css";

interface ContentHeaderButtonsProps {
  onClickUnarchive: () => void;
  onClickEditTest: () => void;
  onClickShowPreview: () => void;
  onClickGetTesters: () => void;
  onClickToggleShowAutomatedInsights: () => void;
  isUnarchiveFetching: boolean;
  hasUnfinishedDeliveries: boolean;
  isShowAutomatedInsights: boolean;
  downloadResultsUrl: string;
  downloadCommentsUrl: string;
  isTestArchived: boolean;
  isFeatureAi: boolean;
  isGuest: boolean;
}

export const ContentHeaderButtons = (props: ContentHeaderButtonsProps) => {
  const {
    onClickUnarchive,
    onClickEditTest,
    onClickShowPreview,
    onClickGetTesters,
    onClickToggleShowAutomatedInsights,
    isUnarchiveFetching,
    hasUnfinishedDeliveries,
    isShowAutomatedInsights,
    downloadResultsUrl,
    downloadCommentsUrl,
    isTestArchived,
    isFeatureAi,
    isGuest,
  } = props;

  let tooltipEditTestButton;
  if (isGuest) {
    tooltipEditTestButton = "Guests can’t edit tests";
  } else if (hasUnfinishedDeliveries) {
    tooltipEditTestButton = textCantEditTest;
  } else {
    tooltipEditTestButton = "Edit test";
  }

  return (
    <div className={styles.contentHeaderButtons}>
      {isTestArchived ? (
        <Button onClick={onClickUnarchive}>
          {isUnarchiveFetching ? "Unarchiving…" : "Unarchive"}
        </Button>
      ) : (
        <>
          <Tooltip
            className={styles.editTestButton}
            content={tooltipEditTestButton}
            small
            center
          >
            <ButtonIcon
              onClick={onClickEditTest}
              icon={regular("pen")}
              disabled={hasUnfinishedDeliveries || isGuest}
            />
          </Tooltip>
          <Tooltip
            className={styles.previewTestButton}
            content={"Preview test"}
            small
            center
          >
            <ButtonIcon onClick={onClickShowPreview} icon={regular("eye")} />
          </Tooltip>
          <ButtonPrimary
            className={styles.getTestersButton}
            onClick={onClickGetTesters}
          >
            <FontAwesomeIcon icon={regular("user-plus")} /> Get testers
          </ButtonPrimary>
        </>
      )}

      <OverflowMenu
        className={styles.overflowMenu}
        customTriggerButton={OverflowMenuTriggerButton}
      >
        <OverflowMenuItem
          className={styles.overflowMenuItemSmallScreenFallback}
          disabled={hasUnfinishedDeliveries}
          onClick={onClickEditTest}
        >
          {hasUnfinishedDeliveries
            ? "Edit test (waiting for testers)"
            : "Edit test"}
        </OverflowMenuItem>
        <OverflowMenuItem
          className={styles.overflowMenuItemSmallScreenFallback}
          onClick={onClickShowPreview}
        >
          Preview test
        </OverflowMenuItem>
        {isFeatureAi === true && (
          <>
            <OverflowMenuItem onClick={onClickToggleShowAutomatedInsights}>
              {isShowAutomatedInsights
                ? "Hide AI from Clips and Report"
                : "Show AI in Clips and Report"}
            </OverflowMenuItem>
            {!isGuest && <hr />}
          </>
        )}
        <OverflowMenuItem href={downloadResultsUrl} download>
          Export all results (CSV)
        </OverflowMenuItem>

        <OverflowMenuItem href={downloadCommentsUrl} download>
          Export notes (CSV)
        </OverflowMenuItem>
      </OverflowMenu>
    </div>
  );
};

const OverflowMenuTriggerButton = ({
  innerRef,
  onClick,
}: {
  innerRef: React.RefObject<HTMLButtonElement>;
  onClick: () => void;
}) => (
  <button
    className={styles.overflowMenuTriggerButton}
    ref={innerRef}
    onClick={onClick}
  >
    <FontAwesomeIcon icon={solid("ellipsis-h")} />
  </button>
);
